import React, { Fragment, useState } from "react";
import Slider from "@material-ui/core/Slider";
import Checkbox from "@material-ui/core/Checkbox";
import Cropper from "react-easy-crop";
import { NotificationContainer } from "react-notifications";

// Components
import getCroppedImg from "../components/ImageEasyCrop/cropImage";
import UploadFile from "../components/uploadFile";
import ButtonOptions from "../components/buttonOptions";
import DownloadButton from "../components/Buttons/DonwloadButton";

// Helpers
import {
  downloadCompressedImage,
  downloadOriginalImage,
} from "../helpers/download";
import { createNotification } from "../helpers/notifications";

// CSS imports
import "../styles/image-easy-crop.css";
import "react-notifications/lib/notifications.css";
import { styles } from "../styles/css-in-js";
import { maxImageHeight, maxImageWidth } from "../helpers/sizes";

const imgMaxLimit = {
  width: 7000,
  height: 17000,
  area: 7000 * 17000,
};

const ImageEasyCrop = () => {
  const [imageSrc, setImageSrc] = useState(null);
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [aspect, setAspect] = useState(1 / 1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const [namePrefix, setNamePrefix] = useState("");
  const [isDownloading, setIsDownloading] = useState(false);
  const [resolution, setResolution] = useState({ width: 400, height: 400 });
  const [isSourceSize, setIsSourceSize] = useState(true);
  const [isWebp, setIsWebp] = useState(true);
  // NOTE: add slider if needed
  const [webpQuality] = useState(75);

  const onCropChange = (crop) => {
    setCrop(crop);
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const onZoomChange = (value) => {
    if (
      imageSize.height / value <= resolution.height ||
      imageSize.width / value <= resolution.width
    ) {
      console.log("not zoomable");
      console.log(
        `Your current resolution is: ${croppedAreaPixels.width}x${croppedAreaPixels.height} `
      );
      if (zoom >= value) setZoom(value);
    } else {
      setZoom(value);
    }
  };

  const onFileChange = async (_file) => {
    if (_file && _file.length > 0) {
      const file = _file[0];
      if (file.type === "image/jpeg" || file.type === "image/png") {
        let imageDataUrl = await readFile(file);

        var img = new Image();

        img.onload = function () {
          const imgWidth = this.width;
          const imgHeight = this.height;
          const imgArea = imgWidth * imgHeight;

          if (
            imgWidth <= imgMaxLimit.width &&
            imgHeight <= imgMaxLimit.height &&
            imgArea <= imgMaxLimit.area
          ) {
            setImageSize({ width: this.width, height: this.height });
            setAspect(this.width / this.height);
            setResolution({ width: this.width, height: this.height });

            setImageSrc(imageDataUrl);
            setCrop({ x: 0, y: 0 });
            setZoom(1);
          } else {
            var message;
            if (imgWidth >= imgMaxLimit.width) {
              message = `Uploaded image has width higher than ${imgMaxLimit.width} pixels`;
            }
            if (imgHeight >= imgMaxLimit.height) {
              message = `Uploaded image has height higher than ${imgMaxLimit.height} pixels`;
            }
            if (imgArea >= imgMaxLimit.area) {
              message = `Uploaded image has area higher than ${imgMaxLimit.area} pixels`;
            }
            createNotification("error", "Upload Error", message);
          }
        };
        img.src = imageDataUrl;
      } else
        createNotification(
          "error",
          "Upload Error",
          "The uploaded file type is not supported"
        );
    }
  };

  const callBackSize = (data) => {
    if (
      imageSize.width === data.size.width &&
      imageSize.height === data.size.height
    ) {
      setIsSourceSize(true);
    } else {
      setIsSourceSize(false);
    }
    setZoom(1);
    setAspect(data.aspect);
    setResolution({ width: data.size.width, height: data.size.height });
  };

  const downloadImage = async (selectedBtnId) => {
    console.log("downloadImage", selectedBtn);
    if (namePrefix === "") {
      createNotification(
        "warning",
        "Ooopps!",
        "Please add a name prefix before downloading the image"
      );
    } else if (!selectedBtnId) {
      createNotification(
        "warning",
        "Ooopps!",
        "Please select a button option first"
      );
    } else {
      try {
        setIsDownloading(true);
        if (
          isSourceSize &&
          imageSize.height <= maxImageHeight &&
          imageSize.width <= maxImageWidth
        ) {
          const isDownloaded = await downloadOriginalImage(
            imageSrc,
            namePrefix,
            isWebp,
            webpQuality
          );
          if (isDownloaded) setIsDownloading(false);
        } else {
          const croppedImageUrl = await getCroppedImg(
            imageSrc,
            croppedAreaPixels,
            "image/jpeg"
          );

          const isDownloaded = await downloadCompressedImage(
            croppedImageUrl,
            croppedAreaPixels,
            namePrefix,
            isWebp,
            webpQuality
          );
          if (isDownloaded) setIsDownloading(false);
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  const [selectedBtn, setSelectedBtn] = useState("");

  const handleChangeSelectedBtn = (id) => {
    setSelectedBtn(id);
  };

  return (
    <div className="ImageEasyCrop">
      <NotificationContainer />

      <div className="centered">
        <UploadFile onSelectFile={onFileChange} />
      </div>

      <div className="centered">
        <div className="aspect-ratio">
          <ButtonOptions
            callBackSize={callBackSize}
            imageSize={imageSize}
            isImageUploaded={imageSrc ? true : false}
            onChangeSelectedBtn={handleChangeSelectedBtn}
          />
        </div>
      </div>

      {imageSrc && (
        <div style={styles.centerContainer}>
          <label>
            convert to <code>.webp</code>
          </label>
          <Checkbox
            checked={isWebp}
            onChange={() => {
              setIsWebp((prev) => !prev);
            }}
          />
        </div>
      )}

      {imageSrc && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "20px",
          }}
          className="download-wrapper"
        >
          <div style={styles.centerContainer}>
            <input
              type="text"
              name="prefix"
              id="file-name-prefix"
              onChange={(e) => setNamePrefix(e.target.value)}
              placeholder="Enter file name prefix"
              style={styles.nameBox}
              required
            />
          </div>
          <div className="button" style={styles.downloadBtnContainer}>
            <DownloadButton
              isDisabled={isDownloading}
              isLoading={isDownloading}
              onClick={() => downloadImage(selectedBtn)}
              label="Download Image"
            />
          </div>
        </div>
      )}

      {imageSrc && (
        <Fragment>
          <div className="crop-container">
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={aspect}
              onCropChange={onCropChange}
              onCropComplete={onCropComplete}
              onZoomChange={onZoomChange}
              zoomWithScroll={false}
            />
          </div>
          <div className="controls">
            <Slider
              value={zoom}
              min={1}
              max={10}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e, zoom) => onZoomChange(zoom)}
              classes={{ root: "slider" }}
            />
          </div>
        </Fragment>
      )}
    </div>
  );
};

const readFile = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
};

export default ImageEasyCrop;
