import {
  limitHeight,
  limitWidth,
} from "../../helpers/sizes";

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
export default async function getCroppedImg(
  imageSrc,
  pixelCrop,
  fileType,
  rotation = 0
) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const width = limitWidth(image.width);
  const height = limitHeight(image.height);

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = width;
  canvas.height = height;

  // draw rotated image and store data.
  ctx.drawImage(image, 0, 0);

  const data = ctx.getImageData(0, 0, width, height);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = limitWidth(pixelCrop.width);
  canvas.height = limitHeight(pixelCrop.height);

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(data, 0 - pixelCrop.x, 0 - pixelCrop.y);

  // As a blob
  return new Promise((resolve) => {
    canvas.toBlob((file) => {
      resolve(URL.createObjectURL(file));
    }, fileType);
  });
}
