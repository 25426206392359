import React from "react";
import Dropzone from "react-dropzone";
import "../styles/dropbox.css";

const UploadFile = ({ onSelectFile }) => {
  return (
    <div className="dropzone">
      <Dropzone onDrop={onSelectFile}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="filedrag">
                <p>Upload source image</p>
              </div>
            </div>
          </section>
        )}
      </Dropzone>
    </div>
  );
};

export default UploadFile;
