export const maxImageWidth = 16382;
export const maxImageHeight = 16382;

export function limitWidth(width) {
  return Math.min(width, maxImageWidth);
}

export function limitHeight(height) {
    return Math.min(height, maxImageHeight);
  }
