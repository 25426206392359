import Jimp from "jimp";
import { srcToWebP } from "webp-converter-browser";
import { blobToBase64 } from "./blobToBase64";
import { limitHeight, limitWidth } from "./sizes";

export const downloadOriginalImage = async (
  dataUrl,
  namePrefix,
  isWebp,
  webpQuality
) => {
  var img = new Image();
  img.src = dataUrl;
  var resolution = `${limitWidth(img.width)}x${limitHeight(img.height)}`;
  var date = new Date();
  var today = "";
  if (date.getMonth() + 1 < 10) {
    today = `${date.getFullYear()}0${date.getMonth() + 1}${date.getDate()}`;
  } else {
    today = `${date.getFullYear()}${date.getMonth() + 1}${date.getDate()}`;
  }
  await Jimp.read(dataUrl)
    .then(async (img) => {
      var filtered_name = namePrefix.replace(/[^a-zA-Z0-9\d-]/g, "");
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";

      let finalUrl;

      if (isWebp) {
        const url = await img.getBase64Async(Jimp.AUTO);
        const webpBlob = await srcToWebP(url, {
          quality: webpQuality / 100,
        });
        const webpBase64 = await blobToBase64(webpBlob);
        finalUrl = webpBase64;
      }

      const extension = isWebp ? "webp" : img.getExtension();

      a.href = finalUrl ? finalUrl : dataUrl;
      a.download = `${filtered_name}-${resolution}-${today}.${extension}`;
      a.click();
      window.URL.revokeObjectURL(finalUrl ? finalUrl : dataUrl);
    })
    .catch((err) => {
      console.log(err);
    });
  return true;
};

export const downloadCompressedImage = async (
  croppedImageUrl,
  crop,
  namePrefix,
  isWebp,
  webpQuality
) => {
  var resolution = "";
  var cropSize = null;
  var crop_size = `${Math.round(limitWidth(crop.width))}x${Math.round(limitHeight(crop.height))}`;

  if (localStorage.getItem("crop_size") !== undefined) {
    cropSize = JSON.parse(localStorage.getItem("crop_size"));
    resolution = cropSize.resolution;
  } else resolution = crop_size;

  var date = new Date();
  var today = "";
  if (date.getMonth() + 1 < 10) {
    today = `${date.getFullYear()}0${date.getMonth() + 1}${date.getDate()}`;
  } else {
    today = `${date.getFullYear()}${date.getMonth() + 1}${date.getDate()}`;
  }
  var a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";

  try {
    const img = await Jimp.read(croppedImageUrl);

    img
      .resize(limitWidth(cropSize.size.width), limitHeight(cropSize.size.height)) // resize
      .quality(100); // set JPEG quality

    // img.getBase64Async(Jimp.AUTO).then((result) => {
    //   var url = result;
    //   a.href = url;
    //   a.download = `${namePrefix}-${resolution}-${today}.png`;
    //   a.click();
    // });

    var url = await img.getBase64Async(Jimp.AUTO);

    if (isWebp) {
      const webpBlob = await srcToWebP(url, {
        quality: webpQuality / 100,
      });
      const webpBase64 = await blobToBase64(webpBlob);
      url = webpBase64;
    }

    const extension = isWebp ? "webp" : img.getExtension();

    a.href = url;
    var file_name = `${namePrefix}-${resolution}-${today}`.toLocaleLowerCase();
    a.download = `${file_name}.${extension}`;
    a.click();
    return true;
  } catch (err) {
    console.log(err);
  }
};
