import clsx from "clsx";
import React, { useRef, useState } from "react";
import sanitizeFileName from "../helpers/sanitizeFileName";
import axios from "axios";

const getDesktopUrl = (url) => {
  return `https://api.screenshotone.com/take?access_key=ym0bfQb7QcrpDw&full_page=true&full_page_scroll=true&viewport_width=1920&viewport_height=1200&full_page_max_height=8191&device_scale_factor=2&format=webp&image_quality=90&block_ads=true&block_cookie_banners=true&block_banners_by_heuristics=false&block_trackers=false&delay=0&timeout=90&user_agent=Mozilla%2F5.0+%28Macintosh%3B+Intel+Mac+OS+X+10_15_7%29+AppleWebKit%2F537.36+%28KHTML%2C+like+Gecko%29+Chrome%2F124.0.0.0+Safari%2F537.36&url=${encodeURIComponent(
    decodeURIComponent(url)
  )}`;
};

const getMobileUrl = (url) => {
  return `https://api.screenshotone.com/take?access_key=ym0bfQb7QcrpDw&full_page=true&full_page_scroll=true&viewport_width=412&viewport_height=915&full_page_max_height=8191&device_scale_factor=2&format=webp&image_quality=90&block_ads=true&block_cookie_banners=true&block_banners_by_heuristics=false&block_trackers=false&delay=0&timeout=90&user_agent=Mozilla%2F5.0+%28Linux%3B+Android+13%3B+SM-G981B%29+AppleWebKit%2F537.36+%28KHTML%2C+like+Gecko%29+Chrome%2F116.0.0.0+Mobile+Safari%2F537.36&url=${encodeURIComponent(
    decodeURIComponent(url)
  )}`;
};

const getToday = () => {
  var date = new Date();

  if (date.getMonth() + 1 < 10) {
    return `${date.getFullYear()}0${date.getMonth() + 1}${date.getDate()}`;
  }

  return `${date.getFullYear()}${date.getMonth() + 1}${date.getDate()}`;
};

function ButtonWrapper({
  loading,
  onClick,
  text,
  alt,
  url,
  refForm,
  disabled,
}) {
  const handleLink = async (event, link) => {
    if (refForm.current) {
      if (refForm.current.checkValidity() === false) {
        refForm.current.reportValidity();
        return;
      }

      event.preventDefault();
      window.open(link);
    }
  };

  return (
    <div className="btn-wrapper">
      <button
        className={clsx("btn", {
          "is-loading": loading,
          "is-disabled": disabled
        })}
        onClick={onClick}
      >
        {loading ? (
          <span>
            <span>Capturing</span>
            <span className="ld ld-ring ld-spin"></span>
          </span>
        ) : (
          text
        )}
      </button>
      <div
        className={clsx("alternate-link", {
          "is-disabled": disabled
        })}
        onClick={(e) => {
          handleLink(e, getDesktopUrl(url));
        }}
      >
        <img src={require("../assets/link.svg")} alt={alt} />
      </div>
    </div>
  );
}

export default function ScreenshotOne() {
  const [url, setUrl] = useState();
  const [customFileName, setCustomFileName] = useState("");
  const [loadingDesktop, setLoadingDesktop] = useState(false);
  const [loadingMobile, setLoadingMobile] = useState(false);

  const handleDownload = async (event, setLoading, fetchUrl, suffix) => {
    if (refForm.current) {
      if (refForm.current.checkValidity() === false) {
        refForm.current.reportValidity();
        return;
      }

      event.preventDefault();
      setLoading(true);

      try {
        const response = await axios.get(fetchUrl, {
          responseType: "blob",
          timeout: 90000,
        });

        const fileUrl = window.URL.createObjectURL(response.data);

        const anchorElement = document.createElement("a");
        anchorElement.href = fileUrl;
        anchorElement.download = sanitizeFileName(
          `${customFileName}-${getToday()}-${suffix}`
        );
        anchorElement.style.display = "none";

        document.body.appendChild(anchorElement);

        anchorElement.click();
        anchorElement.remove();

        window.URL.revokeObjectURL(fileUrl);
      } catch (error) {
        alert("capture failed, check console for details");
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const refForm = useRef();

  return (
    <div className="screenshotone-wrapper">
      <h1>Capture Screenshot</h1>
      <form ref={refForm} className="screenshotone-form">
        <input
          className="name-box"
          type="url"
          name="url"
          value={url}
          onChange={(e) => setUrl(e.currentTarget.value)}
          placeholder="Enter website URL to capture"
          required
        />
        <input
          className="name-box"
          type="text"
          name="filename"
          value={customFileName}
          onChange={(e) => {
            setCustomFileName(e.currentTarget.value);
          }}
          placeholder="Enter file name prefix"
          required
        />
        <ButtonWrapper
          loading={loadingDesktop}
          onClick={(event) => {
            handleDownload(
              event,
              setLoadingDesktop,
              getDesktopUrl(url),
              "desktop.webp"
            );
          }}
          text="Download Desktop Capture"
          alt="link to desktop capture"
          url={getDesktopUrl(url)}
          refForm={refForm}
          disabled={!url || !customFileName}
        />

        <ButtonWrapper
          loading={loadingMobile}
          onClick={(event) => {
            handleDownload(
              event,
              setLoadingMobile,
              getMobileUrl(url),
              "mobile.webp"
            );
          }}
          text="Download Mobile Capture"
          alt="link to mobile capture"
          url={getMobileUrl(url)}
          refForm={refForm}
          disabled={!url || !customFileName}
        />
      </form>
    </div>
  );
}
