import "../../styles/loading.min.css";
import React from "react";
import clsx from "clsx";

const Button = ({ isDisabled, isLoading, onClick, label }) => {
  return (
    <button
      disabled={isDisabled}
      className={clsx("btn", "btn-download", "btn-center", {
        running: isLoading,
      })}
      onClick={onClick}
    >
      {isLoading ? <span className="lds-dual-ring is-active" /> : label}
    </button>
  );
};

export default Button;
