export const styles = {
  nameBox: {
    width: "300px",
    padding: "10px",
    margin: "10px",
  },

  centerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  downloadBtnContainer: {
    padding: "10px",
    marginTop: "5px",
  },
};
