import React from "react";
import "./App.css";
import ImageEasyCrop from "./pages/ImageEasyCrop";
import { BrowserRouter as Router, Route } from "react-router-dom";
import ScreenshotOne from "./pages/ScreenshotOne";

const App = () => {
  return (
    <Router>
      <div>
        <div className="container">
          <Route path="/" exact>
            <div>
              <ImageEasyCrop />
              <ScreenshotOne />
            </div>
          </Route>
        </div>
      </div>
    </Router>
  );
};

export default App;
